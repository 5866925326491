import { wait } from '@idk-web/core-utils';
import {
  signInboxMessage,
  getSignInboxMessageResult,
  InboxSignResponse,
} from '@idk-web/api';

const POLL_INTERVAL_MS = 1000;
const POLL_TIMEOUT_MS = 10 * 60 * 1000;

type InitCallback = (
  data: Extract<InboxSignResponse, { status: 'IN_PROGRESS' }>,
) => void;
type CompleteCallback = () => void;
type ErrorCallback = (error: unknown) => void;
type ProgressCallback = (
  data: Extract<InboxSignResponse, { status: 'IN_PROGRESS' }>,
) => void;
type InitFunction = () => Promise<
  Extract<InboxSignResponse, { status: 'IN_PROGRESS' }>
>;
type CancelFunction = (ref: string) => Promise<void>;
type GetStatusFunction = (ref: string) => Promise<InboxSignResponse>;

export type PendingInboxSignRequest = {
  onInit(callback: InitCallback): PendingInboxSignRequest;
  onComplete(callback: CompleteCallback): PendingInboxSignRequest;
  onError(callback: ErrorCallback): PendingInboxSignRequest;
  onProgress(callback: ProgressCallback): PendingInboxSignRequest;
  start(): StartedInboxSignRequest;
};

export type StartedInboxSignRequest = {
  wait(): Promise<void>;
  cancel(): void;
};

export function sweden(options: {
  messageId: string;
}): PendingInboxSignRequest {
  return start(
    () => signInboxMessage(options.messageId),
    async () => {
      // Not supported
    },
    (ref) => getSignInboxMessageResult(options.messageId, ref),
  );
}

function start(
  init: InitFunction,
  cancel: CancelFunction,
  getStatus: GetStatusFunction,
): PendingInboxSignRequest {
  const initCallbacks: InitCallback[] = [];
  const completeCallbacks: CompleteCallback[] = [];
  const errorCallbacks: ErrorCallback[] = [];
  const progressCallbacks: ProgressCallback[] = [];
  const controller = new AbortController();

  return {
    onInit(callback) {
      initCallbacks.push(callback);
      return this;
    },
    onComplete(callback) {
      completeCallbacks.push(callback);
      return this;
    },
    onError(callback) {
      errorCallbacks.push(callback);
      return this;
    },
    onProgress(callback) {
      progressCallbacks.push(callback);
      return this;
    },
    start() {
      const promise = run(
        init,
        cancel,
        getStatus,
        (data) => initCallbacks.forEach((f) => f(data)),
        () => completeCallbacks.forEach((f) => f()),
        (error) => errorCallbacks.forEach((f) => f(error)),
        (data) => progressCallbacks.forEach((f) => f(data)),
        controller,
      );

      return {
        wait: () => promise,
        cancel: () => controller.abort(),
      };
    },
  };
}

async function run(
  init: InitFunction,
  cancel: CancelFunction,
  getStatus: GetStatusFunction,
  onInit: InitCallback,
  onComplete: CompleteCallback,
  onError: ErrorCallback,
  onProgress: ProgressCallback,
  controller: AbortController,
): Promise<void> {
  let timeLeft = POLL_TIMEOUT_MS;
  let ref: string;

  try {
    const response = await init();
    ref = response.ref;
    onInit(response);
  } catch (e) {
    onError(e);
    throw e;
  }

  controller.signal.addEventListener('abort', () => cancel(ref));

  while (timeLeft > 0) {
    let response: InboxSignResponse;
    try {
      response = await getStatus(ref);
    } catch (e) {
      await wait(POLL_INTERVAL_MS);
      continue;
    }

    if (controller.signal.aborted || timeLeft < 0) {
      break;
    }

    switch (response.status) {
      case 'FAILED':
        onError(response.error);
        throw new Error(response.error);
      case 'COMPLETED': {
        onComplete();
        return;
      }
    }

    timeLeft -= POLL_INTERVAL_MS;

    if (controller.signal.aborted || timeLeft < 0) {
      break;
    } else {
      onProgress(response);
    }

    await wait(POLL_INTERVAL_MS);

    if (controller.signal.aborted || timeLeft < 0) {
      break;
    }
  }

  if (controller.signal.aborted) {
    onError('cancelled');
    throw new Error('cancelled');
  } else {
    onError('timed out');
    throw new Error('timed out');
  }
}
